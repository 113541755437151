<template>
  <div class="wrapper">
    <div class="container">
      <div class="table">
        <div class="row mb-2">
          <div class="mb-2 col-6">
            <label for="exampleFormControlInput1" class="form-label"
              >Choose application</label
            >
            <select
              class="form-select"
              aria-label="Default select example"
              v-model="productFilter"
            >
              <option value="" selected>Choose product</option>
              <option value="Keyboard Fonts Buddy">Keyboard Fonts Buddy</option>
              <option value="Quottie">Quottie</option>
              <option value="GetSynced">GetSynced</option>
              <option value="Lazy Publish">Lazy Publish</option>
              <option value="idVPN">idVPN</option>
            </select>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-10">
            <button class="btn btn-primary" @click="showNewBlogModal = true">
              Create
            </button>
          </div>
        </div>
        <table>
          <thead>
            <tr>
              <th class="table-dark">Title</th>
              <th class="table-dark">Product</th>
              <th class="table-dark">&nbsp;</th>
              <th class="table-dark">&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="blog in paginatedBlogs" :key="blog.id">
              <td style="vertical-align: middle">{{ blog.title }}</td>
              <td style="vertical-align: middle">{{ blog.product }}</td>
              <td>
                <button style="margin-top: 0 !important" @click="getBlog(blog)">
                  Edit
                </button>
              </td>
              <td>
                <button
                  style="margin-top: 0 !important"
                  @click="deleteBlog(blog)"
                >
                  Delete
                </button>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td colspan="100%">
                <div class="pagination">
                  <div
                    v-for="blog in Math.ceil(blogs.length / perPage)"
                    :key="blog"
                    class="page"
                    :class="this.page == blog ? 'active' : ''"
                    @click="this.page = blog"
                  >
                    {{ blog }}
                  </div>
                </div>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </div>

  <Teleport to="body">
    <!-- use the modal component, pass in the prop -->
    <NewBlog
      :show="showNewBlogModal"
      @close="showNewBlogModal = false"
      @addBlog="submitAdd"
    >
      <template #header>
        <h3>Create new blog</h3>
      </template>
    </NewBlog>
    <EditBlog
      :show="showEditBlogModal"
      :blog-data="blog"
      @close="showEditBlogModal = false"
      @updateBlog="submitEdit"
    >
      <template #header>
        <h3>Edit blog</h3>
      </template>
    </EditBlog>
  </Teleport>
</template>

<script>
import blogsRef from "../firebase";
import { getDocs } from "firebase/firestore";
import { setDoc, doc, deleteDoc, addDoc } from "firebase/firestore";
import NewBlog from "./NewBlog.vue";
import EditBlog from "./EditBlog.vue";
import "firebase/compat/firestore";

export default {
  data() {
    return {
      blogs: [],
      filteredBlogs: [],
      blog: {},
      editCityId: null,
      docRef: null,
      showNewBlogModal: false,
      showEditBlogModal: false,
      perPage: 10,
      page: 1,
      productFilter: "",
    };
  },
  components: {
    NewBlog,
    EditBlog,
  },
  methods: {
    async getBlogs() {
      let blogsSnapshot = await getDocs(blogsRef);
      let blogs = [];
      blogsSnapshot.forEach((blog) => {
        let blogData = blog.data();
        blogData.id = blog.id;
        blogs.push(blogData);
      });
      this.blogs = blogs;
      this.filteredBlogs = blogs;
    },
    getBlog(item) {
      this.blog = item;
      let blogRef = doc(blogsRef, item.id);
      this.docRef = blogRef;
      this.showEditBlogModal = true;
    },
    async submitEdit(blog) {
      await setDoc(this.docRef, blog);
      this.getBlogs();
      this.showEditBlogModal = false;
    },
    async submitAdd(blog) {
      await addDoc(blogsRef, blog);
      this.getBlogs();
      this.showNewBlogModal = false;
    },
    async deleteBlog(blog) {
      let blogRef = doc(blogsRef, blog.id);
      await deleteDoc(blogRef);
      this.getBlogs();
      alert("Blog deleted successfully");
    },
    previousPage() {
      if (this.page > 1) this.page -= 1;
    },
    nextPage() {
      if (this.page < Math.ceil(this.blogs.length / this.perPage))
        this.page += 1;
    },
  },
  created() {
    this.getBlogs();
  },
  computed: {
    paginatedBlogs() {
      return this.filteredBlogs.slice(
        (this.page - 1) * this.perPage,
        this.page * this.perPage
      );
    },
  },
  watch: {
    productFilter: function (val) {
      this.filteredBlogs = this.blogs.filter(
        (x) => val == "" || x.product === val
      );
    },
  },
};
</script>

<style lang="scss" scoped>
$gray0: #f6f8ff;
$primary: #636983;
$transition: all 0.2s;

.title {
  text-align: center;
  padding: 40px 0;
  font-size: 40px;
  font-weight: 300;
  color: $primary;
}

.wrapper {
  background-color: $gray0;
  width: 100%;
  min-height: 70vh;

  .line {
    width: 50%;
    margin: 50px auto 0 auto;
    height: 1px;
    background-color: #c3d2e3;
  }
}

.table {
  width: 60%;
  margin: 1.5rem auto;

  table {
    width: 100%;
    font-size: 16px;
    background-color: white;
    border-radius: 10px;

    thead {
      background-color: #f4f7f8;
      border-spacing: 0;
      td {
        font-size: 18px;
        font-weight: 600;
        color: #666;
        padding: 10px;

        &:nth-child(1) {
          border-radius: 10px 0 0 0;
        }
        &:nth-last-child(1) {
          border-radius: 0 10px 0 0;
        }
      }
    }
    tbody {
      tr {
        transition: $transition;

        td {
          padding: 10px;
          color: #666;
        }
        &:hover {
          transform: translateY(-2px);
          background-color: #ededed1c;
        }
      }
    }
    tfoot {
      tr td {
        padding: 3px 10px;
        background-color: #f4f7f8;
        text-align: right;
      }
    }
  }
}

.pagination {
  display: flex;
  position: relative;
  right: 10px;
  justify-content: flex-end;
  gap: 5px;

  .page {
    width: 10px;
    height: 30px;
    flex-basis: 30px;
    text-align: center;
    box-sizing: border-box;
    padding-top: 6px;
    background: #f0f3f4;
    border-radius: 7px;
    color: $primary;
    cursor: pointer;

    &.active {
    }

    &:hover {
    }
  }
}

@media (max-width: 700px) {
  .table {
    width: 90%;
  }
}
</style>
