<template>
  <the-header></the-header>
  <router-view></router-view>
  <the-footer></the-footer>
</template>

<script>
import TheHeader from "./components/UI/TheHeader.vue";
import TheFooter from "./components/UI/TheFooter.vue";
export default {
  name: "App",
  components: {
    TheHeader,
    TheFooter,
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@700&display=swap");
* {
  margin: 0;
  padding: 0;
}
body {
  overflow-x: hidden;
}
#app {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
