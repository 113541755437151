<template>
  <div
    class="main-container"
    style="
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    "
  >
    <div class="container-1">
      <div class="title">
        <div class="h-title">
          <h1>privacy policy</h1>
        </div>
        <a href="mailto:support@keyboardfontsbuddy.app"
          ><h4>support@keyboardfontsbuddy.app</h4></a
        >
      </div>
      <div class="part-2">
        <p>
          Welcome to Keyboard Fonts Buddy, we take the protection of your
          personal data very seriously and treat it confidentially and in
          accordance with the General Data Protection Regulation (GDPR). This
          Privacy Policy applies to the Keyboard Fonts Buddy iOS app
          (hereinafter referred to as "APP"). It explains the nature, purpose
          and scope of data collection in the context of APP use. We would like
          to point out that data transmission on the Internet can have security
          gaps. Complete protection of data against access by third parties is
          not possible.
        </p>
        <p>
          <span class="h">The Controller</span><br />
          The controller within the meaning of Art. 4 No. 7 GDPR for the
          processing of personal data is Keyboard Fonts Buddy (Serhiy Ozhibko)
          of Knyahyni Olʹhy, L’viv, L’vivs’ka oblast, 79000, Ukraine and email
          support@keyboardfontsbuddy.app . If you have any questions about the
          processing of your personal data, as well as your rights regarding
          data protection, please contact us.
        </p>

        <p>
          <span class="h">General storage period of personal data</span><br />
          Unless otherwise stated or specified in this privacy policy, the
          personal data collected by this APP will be stored until you request
          us to delete it, revoke your consent to store it or the purpose for
          storing the data no longer applies. If there is a legal obligation to
          store the data or another legally recognised reason for storing the
          data (e.g., legitimate interest), the personal data concerned will not
          be deleted until the respective reason for storing the data no longer
          applies.
        </p>

        <p>
          <span class="h">Legal basis for the storage of personal data</span
          ><br />
          The processing of personal data is only permitted if there is an
          effective legal basis for the processing of this data. If we process
          your data, this is regularly done on the basis of your consent in
          accordance with Art. 6 Para. 1 lit. a GDPR (e.g., when you voluntarily
          provide your data), for the purpose of fulfilling a contract in
          accordance with Art. 6 Para. 1 lit. b GDPR (e.g., when you use our
          APP) or for the purpose of fulfilling a contract in accordance with
          Art. 6 Para. 1 lit. b GDPR (e.g., when you use our APP) or on the
          basis of legitimate interests pursuant to Art. 6 (1) f GDPR, which are
          always weighed up against your interests.
        </p>

        <p>
          <span class="h">Encryption</span><br />
          For security reasons and to protect all content stored in the server
          database, this APP uses AES 256 bit standard encryption on the server
          side. To protect your data during transmission over the internet, we
          use Transport Layer Security (HTTPS) for read and write operations.
          This encryption prevents the data you transmit from being read by
          unauthorised third parties.
        </p>

        <p>
          <span class="h">Your rights</span><br />
          <span class="i">Revocation of your consent to data processing</span
          ><br />
          Many data processing operations are only possible with your consent.
          In these cases, we will explicitly obtain your consent before we start
          processing your data. You can revoke this consent at any time. For
          this purpose, an informal communication by e-mail to us is sufficient.
          The legality of the data processing operations carried out until the
          revocation remains unaffected by the revocation.
        </p>

        <p>
          <span class="i">Right to object to the collection of data</span><br />
          If data processing is carried out on the basis of Art. 6 (1) f GDPR,
          you have the right to object to the processing of your personal data
          at any time on grounds relating to your particular situation; this
          also applies to profiling based on these provisions. The respective
          legal bases on which processing is based can be found in this privacy
          policy. If you object, we will no longer process the personal data
          concerned unless we can demonstrate compelling legitimate grounds for
          the processing which override your interests, rights and freedoms, or
          where the processing is for the purpose of asserting, exercising or
          defending legal claims.
        </p>
        <p>
          If your personal data are processed for the purposes of direct
          marketing, you have the right to object at any time to the processing
          of personal data concerning you for the purposes of such marketing;
          this also applies to profiling insofar as it is related to such direct
          marketing. If you object, your personal data will subsequently no
          longer be used for the purpose of direct advertising.
        </p>
        <p>
          <span class="i">Right of appeal to a supervisory authority</span
          ><br />
          In the event of breaches of the GDPR, data subjects have a right of
          appeal to a supervisory authority. The right of appeal is without
          prejudice to other administrative or judicial remedies. Information,
          deletion and correction
        </p>
        <p>
          You have the right at any time to free information about your stored
          personal data, its origin and recipient and the purpose of data
          processing as well as the right to correction or deletion of this
          data. You can contact us at any time at the address given in the
          imprint for this purpose and for further questions on the subject of
          personal data.
        </p>
        <p>
          <span class="i">Right to restriction of processing</span><br />
          You have the right to request the restriction of the processing of
          your personal data. To do this, you can contact us at any time at the
          address given in the imprint. The right to restriction of processing
          exists in the following cases:
        </p>
        <ul>
          <li>
            If you dispute the accuracy of your personal data stored by us, we
            usually need time to check this. For the duration of the
            verification, you have the right to request the restriction of the
            processing of your personal data.
          </li>
          <li>
            If the processing of your personal data has happened / is happening
            unlawfully, you can request the restriction of data processing
            instead of erasure.
          </li>
          <li>
            If we no longer need your personal data, but you need it to
            exercise, defend or assert legal claims, you have the right to
            request restriction of the processing of your personal data instead
            of erasure.
          </li>
          <li>
            If you have lodged an objection pursuant to Art. 21 (1) GDPR, a
            balancing of your and our interests must be carried out. As long as
            it has not yet been determined whose interests prevail, you have the
            right to demand the restriction of the processing of your personal
            data.
          </li>
        </ul>

        <p>
          <span class="i">Right to data portability</span><br />
          You have the right to have data that we process automatically on the
          basis of your consent or in performance of a contract handed over to
          you or to a third party in a common, machine-readable format. If you
          request the direct transfer of the data to another controller, this
          will only be done insofar as it is technically feasible.
        </p>

        <p>
          <span class="h">Access rights of the app</span><br />
          In order to provide our services via the APP, we do not require access
          rights.
        </p>

        <p>
          <span class="h"
            >Collection of personal data in the context of APP use</span
          ><br />
          In order to provide our services via the APP, we do not require
          personal data from you.
        </p>

        <p>
          <span class="h"
            >Collection of further data within the scope of APP use</span
          ><br />
          When you use our APP, we also collect the following data:
        </p>
        <ul>
          <li>User Id (for identification)</li>
          <li>Membership status (Free/Pro)</li>
          <li>Language settings</li>
        </ul>
        <p>
          The processing of this personal data is necessary to ensure the
          functionality of the APP. The legal basis for this data processing is
          our legitimate interest within the meaning of Art. 6 para. 1 lit. f
          GDPR, your consent within the meaning of Art. 6 para. 1 lit. a GDPR
          and/or - if a contract has been concluded - the fulfilment of our
          contractual obligations (Art. 6 para. 1 lit. b GDPR).
        </p>

        <p>
          <span class="h"
            >Collection of personal and other data in the context of in-app
            purchases</span
          ><br />
          For the management and analysis of in-app purchases, the APP uses the
          product RevenueCat from Revenue Cat, Inc. (hereinafter "RevenueCat")
          located at 633 Tarava St. Suite 10 San Francisco, CA 94116 USA. The
          transfer is carried out in accordance with the provisions of Art. 46
          GDPR. RevenueCat stores or processes the data and prepares them in a
          web interface. RevenueCat stores the data in the USA or Europe and
          submits to the EU-GDPR (EU 2016/679) per DPA:
          <a href="https://www.revenuecat.com/dpa"
            >https://www.revenuecat.com/dpa</a
          >
        </p>

        <p>
          You consent to the collection of data by the APP and its transmission
          to RevenueCat. If the storage of the provided data by the third-party
          provider is not desired, the use of the APP is to be refrained from.
          Reference is made to the General Terms and Conditions and Privacy
          Policy of RevenueCat:
        </p>
        <ul>
          <li>
            General Terms and Conditions:
            <a href="https://www.revenuecat.com/terms"
              >https://www.revenuecat.com/terms</a
            >
          </li>
          <li>
            Privacy policy:
            <a href="https://www.revenuecat.com/privacy"
              >https://www.revenuecat.com/privacy</a
            >
          </li>
        </ul>
        <p>
          <span class="h">Push messages</span><br />
          The legal basis for Push messages is Art. 6 para. 1 lit. f) GDPR. A
          push service is used to provide you with useful tips and information
          directly on your mobile device or similar devices. When we send a push
          message, we send the message with the corresponding IDs or tokens to
          the Push Notification Service. This then ensures that the push message
          is sent to the devices that wish to receive such a notification. Our
          legitimate interest is to be able to present current information to
          you directly. The personal data will only be processed as long as this
          is necessary for the provision of the function. You have the right to
          object. You can prevent your data from being processed further by
          deactivating the push service in the respective system settings of the
          operating system of your device.
        </p>

        <p>
          <span class="h">Network access</span><br />
          The legal basis for this data processing is Art. 6 para. 1 lit. b)
          GDPR. Your data will be treated confidentially by us and deleted if
          you revoke the rights to use it or it is no longer required to provide
          the services and there is no legal obligation to retain it. The
          provision of personal data is necessary if you wish to make full use
          of our app. However, failure to provide this data could result in you
          not being able to use our app or not being able to use it to its full
          extent.
        </p>

        <p>
          <span class="h">Passing on personal data</span><br />
          Your data will only be passed on if we have informed you of this in
          the above information. Otherwise, personal data will not be disclosed
          without your express consent, unless we are legally obliged to
          disclose data (information to law enforcement agencies and courts;
          information to public bodies that receive data on the basis of
          statutory provisions, e.g., social insurance institutions, tax
          authorities, etc.) or we involve third parties bound to professional
          secrecy to enforce our claims.
        </p>

        <p id="last">
          <span class="h">Changes to this Privacy Policy</span><br />
          We reserve the right to change this privacy policy at any time in
          compliance with legal requirements.
        </p>
      </div>
    </div>
    <the-header :hideFooter="true"></the-header>
  </div>
</template>

<script>
import TheHeader from "../UI/TheHeader.vue";
export default {
  components: {
    TheHeader,
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/styles/privacy.scss";
</style>
