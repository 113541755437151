<template>
  <div
    class="main-container"
    style="
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    "
  >
    <div class="container-1">
      <div class="title">
        <div class="h-title">
          <h1>terms & conditions</h1>
        </div>
        <a href="mailto:support@keyboardfontsbuddy.app"
          ><h4>support@keyboardfontsbuddy.app</h4></a
        >
      </div>
      <div class="part-2">
        <p class="paragraph-1">
          These Terms and Conditions (the “Terms”) constitute a legal agreement
          between you and Keyboard Fonts Buddy (Serhiy Ozhibko) of Knyahyni
          Olʹhy, L’viv, L’vivs’ka oblast, 79000, Ukraine and email
          support@keyboardfontsbuddy.app  governing the use of our App and our
          Services. We license use of our App to you on the basis of these
          Terms. We do not sell our App to you, and we remain the owner of our
          App at all times.
        </p>
        <ol>
          <li class="list-group">
            <span class="th">Terms and Conditions</span>
            <ol>
              <li>
                The provisions set out in these Terms govern your access to and
                your use of our App and shall constitute a legally binding
                agreement between you and us. We may change such terms from time
                to time and shall notify you accordingly if we do. If you do not
                agree to such terms, you must not use our App.
              </li>
              <li>
                Subject to you agreeing to abide by these Terms, we hereby grant
                to you a revocable, non-exclusive and non-transferable licence
                to use our App on these Terms.
              </li>
              <li>
                By using the App, you agree and acknowledge that you have read
                the terms set out in these Terms and agree to be bound by and
                comply with them.
              </li>
              <li>
                We reserve the right to, without any notice, explanation or
                liability and in our sole discretion, refuse to allow you or
                suspend your access to our App at any time, or remove or edit
                content on our App.
              </li>
              <li>
                We reserve the right to change, modify, suspend or discontinue
                any portion of the Services, our App, services, and/or other
                software provided by us in connection with any of the foregoing
                at any time. You agree that access to or operation of any of the
                foregoing may from time to time be interrupted or encounter
                technical difficulties.
              </li>
              <li>
                The following additional terms also apply to your use of our App
                and form part of these Terms:
              </li>
              <li>
                Our Privacy Policy sets out our policy concerning the
                collection, use and disclosure of your Personal Data in
                compliance with the General Data Protection Regulation. By using
                our App, you consent to our collection, use and disclosure of
                your Data in the manner set out in our Privacy Policy and you
                warrant that all Data provided by you is accurate. Should you
                wish to update your Data and/or withdraw your consent to our
                collection, use and disclosure of your Data, or should you have
                any feedback or enquiries relating to your Data, please contact
                us.
              </li>
            </ol>
          </li>

          <li class="list-group">
            <span class="th">Subscription</span>
            <ol>
              <li>
                Our Premium Services may require payment of subscription fees
                and/or other ad-hoc or ancillary fees before you can access or
                use them (“Fees”). These Fees will be notified to you through
                our App.
              </li>
              <li>
                If you purchase a recurring subscription from us, the
                subscription period shall be renewed automatically at the expiry
                of each subscription period, until terminated successfully
                through our App.
              </li>
              <li>
                Any Fees due in relation to your Account must be paid by their
                due date for payment, as notified to you through our App or
                otherwise. Failure to make timely payment of the Fees may result
                in the suspension or termination of your access to your Account
                and/or our App or any of the Services.
              </li>
              <li>
                Our Fees may be amended from time to time at our discretion. We
                will provide you reasonably advanced written notice of any
                amendment of recurring Fees. Your continued use of a recurring
                subscription will constitute acceptance of the amended Fees.
              </li>
              <li>
                You shall be responsible for any applicable taxes (including any
                goods and services tax) under these Terms.
              </li>
              <li>
                You acknowledge and agree that you are subject to the applicable
                user agreement of any third-party payment methods. We shall not
                be liable for any failure, disruption or error in connection
                with your chosen payment method. We reserve the right at any
                time to modify or discontinue, temporarily or permanently, any
                payment method without notice to you or giving any reason.
              </li>
            </ol>
          </li>

          <li class="list-group">
            <span class="th">Restrictions</span>
            <ol>
              <li>
                Except as expressly set out in this Agreement or as permitted by
                any applicable law, you undertake:
                <ol>
                  <li>
                    not to reproduce, copy, modify, adapt, translate, publish,
                    display, communicate, transmit, sell, exploit or use the
                    whole or any part of any Service, our App or any of the
                    contents therein for any commercial or other purposes;
                  </li>
                  <li>
                    not to disassemble, decompile, reverse-engineer or create
                    derivative works based on the whole or any part of the
                    source code of our App nor attempt to do any such thing, or
                    to reproduce, display or otherwise provide access to the
                    Services, our App or any of the contents therein, including
                    but not limited to framing, mirroring, linking, spidering,
                    scraping or any other technological means;
                  </li>
                  <li>
                    not to provide or otherwise make available our App in whole
                    or in part (including but not limited to program listings,
                    object and source program listings, object code and source
                    code), in any form to any person without prior written
                    consent from us;
                  </li>
                  <li>
                    to include our copyright notice on all entire and partial
                    copies you make of our App on any medium;
                  </li>
                  <li>
                    to comply with all applicable technology control or export
                    laws and regulations; and
                  </li>
                  <li>
                    not to disrupt, disable, or otherwise impair the proper
                    working of the Services, our App or our servers, such as
                    through hacking, cyber-attacks (including but not limited to
                    denial-of-service attacks), tampering or reprogramming.
                  </li>
                </ol>
              </li>
            </ol>
          </li>

          <li class="list-group">
            <span class="th">Intellectual property rights</span>
            <ol>
              <li>
                You acknowledge that all intellectual property rights in our App
                anywhere in the world belong to us, that rights in our App are
                licensed (not sold) to you, and that you have no rights in, or
                to, our App other than the right to use them in accordance with
                these Terms.
              </li>
              <li>
                Any intellectual property rights in content uploaded by you to
                our App shall continue to belong to you or their respective
                owners. You agree that you grant us a royalty-free and
                non-exclusive licence to use, reproduce, publish and display
                such intellectual property rights for the purposes of performing
                the Services, promotional purposes, internal administrative
                purposes and any other purposes set out in these Terms,
                including for the purpose of improving the Services and our
                responses to users of the App.
              </li>
              <li>
                You acknowledge that you have no right to have access to our App
                in source code form.
              </li>
              <li>
                Save for internal distribution amongst your employees and
                persons authorised by you for your internal business purposes
                and any other purposes contemplated under these Terms or the
                App, you must not modify the paper or digital copies of any
                materials you have printed off or downloaded from our App in any
                way and you must not use any illustrations, photographs, video
                or audio sequences or any graphics separately from any
                accompanying text.
              </li>
              <li>
                Our status (and that of any identified contributors) as the
                authors of content on our App must always be acknowledged.
              </li>
              <li>
                You must not use any part of the content on our App for
                commercial purposes not specified on our App without obtaining a
                licence to do so from us or our licensors.
              </li>
            </ol>
          </li>

          <li class="list-group">
            <span class="th">Warranties</span>
            <ol>
              <li>
                While we make all efforts to maintain the accuracy of the
                information on our App, we provide the Services, App and all
                Related Content on an “as is” and “as available” basis, unless
                otherwise specified in writing. We make no representations or
                warranties of any kind, express or implied, as to the operation
                of any of the foregoing, unless otherwise specified in writing.
              </li>
              <li>
                No Third Party is authorised to make any statement or
                representation for and on behalf of us.
              </li>
              <li>
                To the full extent permissible by law, we disclaim all
                warranties, express or implied, relating to our App or any
                Services, including but not limited to implied warranties of
                merchantability and fitness for a particular purpose. We do not
                warrant that the Services, our App, the Related Content, or
                electronic communications sent by us are free of viruses or
                other harmful components.
              </li>
            </ol>
          </li>

          <li class="list-group">
            <span class="th">Limitation of Liability</span>
            <ol>
              <li>
                We are not liable for the completeness, accuracy or correctness
                of any information uploaded on our App and any Related Content.
                You expressly agree that your use of the Services and our App is
                at your sole risk.
              </li>
              <li>
                We do not assist with dispute resolution between any you and any
                Third Party and are not obliged at any time to adjudicate on any
                such dispute. In the event of any dispute, you are responsible
                for contacting the relevant Third Party. Without prejudice to
                the foregoing, we remain entitled at all times to investigate at
                our discretion any complaint regarding the use of our App or any
                suspected unlawful activity and to take any action that we deem
                appropriate, including to file a report with the appropriate
                authorities.
              </li>
              <li>
                You agree not to use the Services, our App and the Related
                Content for any re-sale purposes, and we have no liability to
                you, whether in contract, tort (including negligence), breach of
                statutory duty, or otherwise, arising under or in connection
                with these Terms (including but not limited to the use of, or
                inability to use, the Services, our App or any other website or
                software) for:
                <ol>
                  <li>loss of profits, sales, business, or revenue;</li>
                  <li>business interruption;</li>
                  <li>loss of anticipated savings;</li>
                  <li>loss or corruption of data or information;</li>
                  <li>
                    loss of business opportunity, goodwill or reputation; or
                  </li>
                  <li>any other indirect or consequential loss or damage.</li>
                </ol>
              </li>
              <li>
                Nothing in these Terms shall limit or exclude our liability for:
                <ol>
                  <li>
                    death or personal injury resulting from our negligence;
                  </li>
                  <li>fraud; and/or</li>
                  <li>
                    any other matter in respect of which we are prohibited under
                    applicable law from limiting or excluding our liability.
                  </li>
                </ol>
              </li>
              <li>
                Our App is not intended to serve a record-keeping function and
                we shall not be liable for any loss of data or content.
              </li>
              <li>
                These Terms set out the full extent of our obligations and
                liabilities in respect of the supply of the Services and our
                App. Except as expressly stated in these Terms, there are no
                conditions, warranties, representations, or other terms, express
                or implied, that are binding on us. Any condition, warranty,
                representation, or other term concerning the supply of the
                Services and our App which might otherwise be implied into, or
                incorporated in, these Terms whether by statute, common law or
                otherwise, is excluded to the fullest extent permitted by law.
              </li>
            </ol>
          </li>

          <li class="list-group">
            <span class="th">Indemnity</span><br />
            You agree to indemnify and hold us, our related corporations, and
            our respective directors, officers, employees, agents and
            representatives, independent contractors, licensees, successors and
            assigns harmless from and against all claims, losses, expenses,
            damages and costs (including but not limited to direct, incidental,
            consequential, exemplary and indirect damages), and reasonable legal
            fees, resulting from or arising out of your act, default or
            omission, whether in your use of our App, Services, and/or any
            websites or software in relation thereto or otherwise, and whether
            in respect of your breach of these Terms or any laws or regulations
            or otherwise.
          </li>

          <li class="list-group">
            <span class="th">Other important terms</span>
            <ol>
              <li>
                We may transfer our rights and obligations under these Terms to
                another organisation, but this will not affect your rights or
                obligations under these Terms.
              </li>
              <li>
                You may only transfer your rights or your obligations under
                these Terms to another person if we agree in writing.
              </li>
              <li>
                No joint venture, partnership or agency or employment
                relationship has arisen by reason of these Terms.
              </li>
              <li>
                These Terms and any document expressly referred to in it
                constitutes the entire agreement between us regarding their
                subject matter, and supersedes and extinguishes all previous
                agreements, promises, assurances, warranties, representations
                and understandings between us, whether written or oral, relating
                to that subject matter. You agree that you shall have no
                remedies in respect of any statement, representation, assurance
                or warranty (whether made innocently or negligently) that is not
                set out in these Terms, or any document expressly referred to in
                it. You agree that you shall have no claim for innocent or
                negligent misrepresentation or negligent misstatement based on
                any statement in these Terms or any document expressly referred
                to in it.
              </li>
              <li>
                If we fail to insist that you perform any of your obligations
                under these Terms, or if we do not enforce our rights against
                you, or if we delay in doing so, that will not mean that we have
                waived our rights against you and will not mean that you do not
                have to comply with those obligations. If we do waive a default
                by you, we will only do so in writing, and that will not mean
                that we will automatically waive any later default by you.
              </li>
              <li>
                Each of the conditions of these Terms operates separately. If
                any court or competent authority decides that any of them are
                unlawful or unenforceable, the remaining conditions will remain
                in full force and effect.
              </li>
              <li>
                These Terms, its subject matter and its formation, and any other
                disputes or claims in connection therewith, are governed by
                Turkish law. In the event of any such disputes or claims in
                connection with these Terms, you agree to first engage in good
                faith discussions with us to resolve such dispute or claim. If
                such dispute or claim is not resolved within sixty (60) days, we
                both irrevocably submit to the exclusive jurisdiction of the
                courts of Turkey.
              </li>
            </ol>
          </li>
        </ol>

        <p class="paragraph-3">
          contact us<br />
          please, email me personally at
          <a href="mailto:support@idvpn.app"
            >support@idvpn.app</a
          >
          and i'll help you get this sorted.
        </p>
      </div>
    </div>
    <the-header :hideFooter="true"></the-header>
  </div>
</template>

<script>
import TheHeader from "../UI/TheHeader.vue";
export default {
  components: {
    TheHeader,
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/styles/terms-conditions.scss";
</style>
