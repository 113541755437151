<template>
  <div
    class="main-container w-container"
    style="
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    "
  >
    <div class="container-1">
      <div class="first-container">
        <div class="title">
          <h1 id="t1">fonts keyboard on the app store</h1>
        </div>
        <a
          href="https://apps.apple.com/app/keyboard-fonts-buddy/id1614155693"
          target="_blank"
          ><img class="left" src="../../assets/images/CTA.svg" alt=""
        /></a>
      </div>
    </div>

    <div class="container-2">
      <div class="second-container">
        <div class="title">
          <h1>fonts, emojis, and symbols</h1>
        </div>
        <img
          src="../../assets/images/Ill01.png"
          style="
            max-width: 100%;
            vertical-align: middle;
            display: inline-block;
            border: 0;
            z-index: 1;
          "
          loading="lazy"
          alt=""
        />
      </div>
      <div class="illustrationone">
        <img
          class="image"
          src="../../assets/images/Circle01.png"
          loading="lazy"
          alt=""
        />
      </div>
    </div>

    <div class="container-3">
      <div class="second-container">
        <div class="title">
          <h1>better social media</h1>
        </div>
        <img
          style="
            max-width: 100%;
            vertical-align: middle;
            display: inline-block;
            border: 0;
            z-index: 1;
          "
          src="../../assets/images/Ill02.png"
          loading="lazy"
          alt=""
        />
      </div>
    </div>

    <div class="container-4">
      <div class="second-container">
        <div class="title">
          <h1>choose keyboard themes</h1>
        </div>
        <img
          src="../../assets/images/Ill03.png"
          style="
            max-width: 100%;
            vertical-align: middle;
            display: inline-block;
            border: 0;
            z-index: 1;
          "
          loading="lazy"
          alt=""
        />
      </div>
      <div class="illustrationone">
        <img
          class="image"
          src="../../assets/images/Circle02.png"
          loading="lazy"
          alt=""
        />
      </div>
    </div>

    <div class="container-5">
      <div class="second-container">
        <div class="title">
          <h1>louder than words</h1>
        </div>
        <img
          src="../../assets/images/Ill04.png"
          style="
            max-width: 100%;
            vertical-align: middle;
            display: inline-block;
            border: 0;
            z-index: 1;
          "
          loading="lazy"
          alt=""
        />
      </div>
      <div class="illustrationone">
        <img
          class="image"
          src="../../assets/images/Circle03.png"
          loading="lazy"
          alt=""
        />
      </div>
    </div>

    <div class="container-6">
      <div class="first-container">
        <div class="title">
          <h1 id="t3">stand out from the crowd</h1>
        </div>
        <a
          href="https://apps.apple.com/app/keyboard-fonts-buddy/id1614155693"
          target="_blank"
          ><img class="left" src="../../assets/images/CTA.svg" alt=""
        /></a>
      </div>
    </div>
    <the-header :hideFooter="true"></the-header>
  </div>
</template>

<script>
import TheHeader from "../UI/TheHeader.vue";
export default {
  components: {
    TheHeader,
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/styles/home.scss";

.container-2 {
  position: relative;
  .illustrationone {
    // height: 375px;
    position: absolute;
    left: 0;
    bottom: -5px;
  }
}
.container-4 {
  position: relative;
  display: -webkit-flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -webkit-justify-content: center;

  .illustrationone {
    position: absolute;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    right: 37px;
    width: 100%;
    height: 225px;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
}
.container-5 {
  position: relative;
  display: -webkit-flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -webkit-justify-content: center;
  .illustrationone {
    position: absolute;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    left: 0;
    width: 100%;
    height: 256px;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
}
</style>
