<template>
  <div
    style="
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    "
  >
    <div
      class="blog-container"
      style="
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
      "
    >
      <div class="container-1">
        <div class="header-image">
          <img
            :src="
              'https://firebasestorage.googleapis.com/v0/b/blogs-94dc9.appspot.com/o/PhotoGallery%2F' +
              getBlog?.headerImage +
              '?alt=media'
            "
          />
        </div>
        <div class="title">
          <div class="h-title">
            <h1>{{ getBlog.title }}</h1>
          </div>
        </div>

        <div class="datepart">
          <p>
            {{ (new Date(getBlog.createdAt.seconds * 1000) + "").slice(4, 15) }}
          </p>
        </div>
        <div class="part-2">
          <div class="content" v-html="getBlog?.description"></div>
        </div>
      </div>
    </div>
    <div
      class="blog-container"
      style="
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
      "
    >
      <div class="container-1">
        <div class="blog-title">
          <div class="h-title" style="margin-bottom: 0 !important">
            <h1>RECENT POSTS</h1>
          </div>
        </div>
        <div class="part-3">
          <div class="recent-blogs">
            <div
              v-for="(blog, index) in blogs.slice(-3)"
              :key="blog.id"
              :class="'postImage div' + (index + 1)"
            >
              <img
                :src="
                  'https://firebasestorage.googleapis.com/v0/b/blogs-94dc9.appspot.com/o/PhotoGallery%2F' +
                  blog.postImage +
                  '?alt=media'
                "
              />
              <p>
                {{
                  (new Date(blog.createdAt.seconds * 1000) + "").slice(4, 15)
                }}
              </p>
              <router-link class="blog-link" :to="'/blog/' + blog.id"
                ><p>
                  {{ blog.title }}
                </p>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-6">
      <div class="first-container">
        <div class="title">
          <h1 id="t3">stand out from the crowd</h1>
        </div>
        <a
          href="https://apps.apple.com/app/keyboard-fonts-buddy/id1614155693"
          target="_blank"
          ><img class="left" src="../../assets/images/CTA.svg" alt=""
        /></a>
      </div>
    </div>
    <the-header :hideFooter="true"></the-header>
  </div>
</template>

<script>
import blogsRef from "../firebase";
import { getDocs } from "firebase/firestore";
import "firebase/compat/firestore";
import TheHeader from "../UI/TheHeader.vue";
export default {
  data() {
    return {
      blogs: [],
      blog: {},
    };
  },
  components: {
    TheHeader,
  },
  methods: {
    async getBlogs() {
      let blogsSnapshot = await getDocs(blogsRef);
      let blogs = [];
      blogsSnapshot.forEach((blog) => {
        let blogData = blog.data();
        blogData.id = blog.id;
        if (blogData.product == "Keyboard Fonts Buddy") blogs.push(blogData);
      });
      this.blogs = blogs;
    },
  },

  created() {
    this.getBlogs();
  },
  computed: {
    getBlog() {
      return this.blogs.find((x) => x.id == this.$route.params.id);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/styles/home.scss";

.container-1 {
  .part-2 {
    width: 100%;
    .content {
      /deep/ p {
        font-size: 24px;
        line-height: 32px;
        padding-bottom: 32px;
        margin-bottom: 0;
      }
      /deep/ img {
        object-fit: cover;
        border-radius: 20px;
        margin: 96px 0;
        max-width: 1140px;
        text-align: center;
        display: block;
      }
      /deep/ p:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
      }
      /deep/ ul {
        margin-bottom: 2rem;
        margin-left: 3.5rem;
        margin-right: 2rem;
        font-size: 24px;
        line-height: 32px;
        color: #252525;
        word-break: break-all;
      }
      /deep/ h1 {
        font-size: 64px;
        padding: 2rem 0;
      }
      /deep/ strong {
        display: block;
      }
    }
  }
}

@media screen and (max-width: 1440px) {
  .container-1 {
    .part-2 {
      width: 100%;

      .content {
        /deep/ p {
          // padding: 0 2rem;
          font-size: 24px;
          padding-bottom: 32px;
          line-height: 32px;
        }
        /deep/ img {
          object-fit: cover;
          border-radius: 20px;
          margin: 96px 0;
          max-width: 100%;
        }
        /deep/ p:last-child {
          margin-bottom: 0;
          padding-bottom: 0;
        }

        /deep/ ul {
          margin-bottom: 2rem;
          margin-left: 3.5rem;
          margin-right: 2rem;
          font-size: 24px;
          line-height: 32px;
          color: #252525;
          word-break: break-all;
        }

        /deep/ h1 {
          font-size: 64px;
          padding: 2rem 0;
        }
      }
    }
  }
}

// //
// ul {
//       padding-bottom: 2rem;
//       padding-left: 1.5rem;
//       padding-right: 2rem;
//       font-size: 24px;
//       line-height: 32px;
//       color: #252525;
//       word-break: break-all;
//     }
//

@media screen and (max-width: 1200px) {
  .container-1 {
    .part-2 {
      width: 100%;

      .content {
        /deep/ p {
          padding: 0 2rem;
          font-size: 24px;
          padding-bottom: 32px;
          line-height: 32px;
        }
        /deep/ img {
          object-fit: cover;
          border-radius: 20px;
          margin: 96px 0;
          max-width: 100%;
        }
        /deep/ p:last-child {
          margin-bottom: 0;
          padding-bottom: 0;
        }
        /deep/ ul {
          margin-bottom: 2rem;
          margin-left: 3.5rem;
          margin-right: 2rem;
          font-size: 24px;
          line-height: 32px;
          color: #252525;
          word-break: break-all;
        }

        /deep/ h1 {
          font-size: 64px;
          padding: 2rem;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .container-1 {
    .header-image {
      img {
        height: auto;
        width: 100%;
        max-width: 100%;
        max-height: unset;
        aspect-ratio: 1/1;
        border-radius: 32px;
      }
    }
    .part-2 {
      width: 100%;

      .content {
        /deep/ p {
          // padding: 0 1.5rem;
          font-size: 16px;
          line-height: 24px;
          padding-bottom: 24px;

          padding-left: 1.5rem;
          padding-right: 1.5rem;
          padding-bottom: 1.5rem;
          a {
            word-break: break-all;
          }

          ul {
            font-size: 16px;
            margin-left: 2.6rem;
            margin-right: 1.5rem;
            line-height: 24px;
            margin-bottom: 1.5rem;
          }
        }
        /deep/ p:has(img) {
          padding-bottom: 0;
        }
        /deep/ img {
          margin: 64px 0;
          object-fit: cover;
          width: 100%;
          height: auto;
          aspect-ratio: 1/1;
          max-width: 100%;
        }

        /deep/ p:last-child {
          margin-bottom: 0;
          padding-bottom: 0;

          padding-left: 1.5rem;
          padding-right: 1.5rem;
        }

        /deep/ ul {
          font-size: 16px;
          margin-left: 2.6rem;
          margin-right: 1.5rem;
          line-height: 24px;
          margin-bottom: 1.5rem;
        }

        /deep/ h1 {
          padding: 1.5rem;
          font-size: 32px;
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .container-1 {
    .header-image {
      img {
        height: unset;
        width: 100%;
        max-width: 100%;
        max-height: unset;
        aspect-ratio: 1/1;
        border-radius: 32px;
      }
    }
    .part-2 {
      width: 100% !important;
      .content {
        /deep/ img {
          max-width: 100%;
          width: 100%;
          object-fit: cover;
          border-radius: 32px;
          aspect-ratio: 1/1;
          // max-height: 256px;
        }
        /deep/ h1 {
          font-size: 32px;
          padding: 1.5rem;
        }
      }
    }
    .part-3 {
      width: calc(100% - 48px);
      .recent-blogs {
        img {
          // height: 256px !important;
          width: 100%;
          border-radius: 32px;
          max-width: 100%;
          // max-height: 256px !important;
          aspect-ratio: 1/1;
        }
      }
    }
  }
}
@import "../../assets/styles/blog.scss";
</style>
